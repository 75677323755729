/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-stacking": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h2", [_vm._v("Pause Prescription")]),
            _c("p", [
              _c("span", { staticClass: "text-muted" }, [
                _vm._v("Currently paused until")
              ]),
              _vm.rowData.resumecollection
                ? _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseUnixDate(
                            _vm.rowData.resumecollection,
                            "yyyy-MM-dd"
                          )
                        ) +
                        " "
                    )
                  ])
                : _c("b", [_vm._v("Indefinite")])
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-7", attrs: { "align-h": "between" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { pill: "", variant: "outline-dark", size: "lg" },
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "outline-dark",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Remove Pause ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "outline-dark",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Update Pause ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }